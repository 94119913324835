import React from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Utils from "../../../../../services/utils";
import FormGroup from "../../../../../components/FormGroup";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button";

const BankbooksFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();

    const {handleSubmit, register, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({number: null});
        setFilterData(Utils.objectToSearchParams({number: ''}))
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                <FormGroup label={t('labels.number')}>
                    <Input defaultValue={params.get('number')} $small type="text" {...register('number')}/>
                </FormGroup>
                <div className="mb-20">
                    <Button $small $fluid>{t('labels.apply')}</Button>
                </div>
                <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
            </form>
        </>
    );
};

export default BankbooksFilter;