import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import useFetchItems from "../../../../hooks/useFetchItems";
import HeadingWithButtons from "../../../../components/HeadingWithButtons";
import { DivElem, GridCal, DivHead } from '../../../../components/Table';
import MonthFilter from '../../../../components/MonthFilter';
import TaskStatisticsFilter from './TaskStatisticsFilter';
import TaskModal from './TaskStatisticsModal';
import TaskStatisticsServices from '../../../../services/operator/taskStatisticsServices';
import Preloader from '../../../../components/Preloader';
import * as FileSaver from 'file-saver';
import { useLocation} from "react-router-dom";
import * as XLSX from "xlsx-js-style"
import {toast} from "react-toastify";

const TasksStatics = ({role, statusText}) => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const [visible, setVisible] = useState(false)
    const [params2, setParams2] = useState()
    const [userId, setUserId] = useState();
    const [arr, setArr] = useState()
    const [loading2, setLoading2] = useState(false)
    let query = new URLSearchParams(useLocation().search);
    const [params3] = useState(query);

    useEffect(() => {
        setBreadcrumbs([
            {
                path: null,
                title: t('pages.tasks.title')
            },
            statusText && {
                path: null,
                title: statusText
            }
        ])
    }, [setBreadcrumbs, t]); // eslint-disable-line react-hooks/exhaustive-deps

    const {items, params, setFilterData} = useFetchItems(TaskStatisticsServices.index, role)

    useEffect(() => {
        if(items){
            const dd = new Date(items[0].date);
            let rr = Array(dd.getDay()-1 < 0 ? 6 : dd.getDay()-1).fill(0)
            setArr(rr);
        }
    }, [items])
    const tableConfig = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

    const openModal = async (date, id) => {
        let str = `date=${date}&user_id=${id}`;
        setUserId(id);
        setParams2(str)
        setVisible(true);
    }


    function createWs(data, fields, titles, days, head, count1, count2) {
        const ws = XLSX.utils.json_to_sheet(
            data, 
            { 
                header: fields
            }
        )
        const range = XLSX.utils.decode_range(ws['!ref'])

        for(let c = range.s.c; c <= range.e.c; c++) {
          const header = XLSX.utils.encode_col(c) + '1'
          const header2 = XLSX.utils.encode_col(c) + '6'
          ws[header].v = titles[ ws[header].v ]
          ws[header2].v = ws[header].v
          ws[header2].s = {
            alignment: { horizontal: "center"  },
            font: {bold: true}
          }
        }

        for(let c = range.s.c+count1; c <= range.e.c-count2; c++) {
            const tit = XLSX.utils.encode_col(c) + '6';
            let isWorkDay = true;
            for(let i =0; i<days.length; i++){
                if(ws[tit].v === days[i].date){
                    isWorkDay = days[i].is_day_off
                }
            }
            for(let r = range.s.r+6; r<=range.e.r+1; r++){
                const header = XLSX.utils.encode_col(c) + r;
                let isColor = false;
                let isDone = false;
                if(ws[header].v !== ' ' && ws[header].v !== null && r > 6){
                    if(ws['F'+r].v > ws[header].v){
                        isColor = true;
                    }
                    else if(ws['F'+r].v <= ws[header].v){
                        isDone = true;
                    }
                }
                ws[header].s = {
                    alignment: { horizontal: "center"  },
                    fill: { fgColor: { 
                        rgb: isWorkDay ? "d9dbe1" : isColor ? 'f2ac57': isDone ? '14a38b' : "ff7171"} 
                    },
                    font: {bold: r === 6}
                }
            }
        }

        ws['!merges'] = [
            {s: {r: 0, c: 0}, e: {r: 0, c: range.e.c}},
            {s: {r: 1, c: 0}, e: {r: 1, c: range.e.c}},
            {s: {r: 4, c: 7}, e: {r: 4, c: 37}}
        ];

        ws.A1.v = head[0]
        ws.A2.v = head[1]
        ws.A1.s = {
            alignment: { horizontal: "center"  }
        }
        ws.A2.s = {
            alignment: { horizontal: "center"  }
        }
        /*ws.H5.s = {
            alignment: { horizontal: "center"  }
        }*/


        const array = []
        for(let c = range.s.c; c <= range.e.c; c++) {
            let max_length = 0;
            for(let r = range.s.r+6; r<=range.e.r+1; r++){
                const header = XLSX.utils.encode_col(c) + r;
                if(max_length < ws[header]?.v?.toString().length){
                    max_length = ws[header]?.v.toString().length
                }
            }
            array.push({ wch: max_length })
        }
        ws["!cols"] = array;
        return ws
    }

    const exportToCSV = async (type, count1, count2) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let date = new Date();
        const fileName = `tasks_statistic_${date.getFullYear()}_${date.getMonth()+1}_${date.getDate()}_${date.toLocaleTimeString()}`;
        setLoading2(true)

        let indications = []
        let df = document.getElementById('rdjugvd');
        df.textContent = "Запрос на получение данных отправлен";
        const data1 = await TaskStatisticsServices.exportByPage(`role=${role}&type=${type}&stat=1&` + params.toString())

        if(data1.statusCode !== 200){
            toast.error(data1.message)
            setLoading2(false)
            return
        }

        let titles = data1.content.headings.prepend;

        let columns = data1.content.headings.columns;

        if(data1.content.total <= 0){
            toast.error('Данные для экспорта отсутствуют')
            setLoading2(false)
            return
        }
        
        for(let i =0; i<columns?.days?.length; i++){
            titles[`${columns.days[i].date}`] = columns.days[i].date;
        }

        let append = data1.content.headings.append;

        for(let key in append){
            titles[`${key}`] = append[key]
        }

        let count_items = 0;

        for(let i = data1.content.current_page ; i<=data1.content.total_pages; i++){
            df.textContent = 'Получено данных ' + count_items + " из " + data1.content.total;
            let resu = await TaskStatisticsServices.exportByPage(`page=${i}&type=${type}&stat=0&` + params.toString());

            if(!resu.content.items.length){
                setLoading2(false)
                return
            }
            if(i === 1){
                let obj = {}
                for(let key in resu.content.items[0]){
                    obj[`${key}`] = ' '
                    if(resu.content.items[0].tasks){
                        for(let j=0;j<resu.content.items[0].tasks.length; j++){
                            obj[`${resu.content.items[0].tasks[j].day}`] = resu.content.items[0].tasks[j].day
                        }
                    }
                }
                indications = [resu.content.items[0], {}, {}, columns?.month ? {'01':columns?.month} : {}, obj]
            }
            indications = indications.concat(...resu.content.items);

            count_items += resu.content.items.length;
        }

        df.textContent = 'Идет формирование exel документа';
        const fields = []
        for(let key in indications[0]){
            if(key === "tasks"){
                for(let i = 0; i<indications[0][key].length; i++){
                    fields.push(indications[0][key][i].day)
                }
            }else{
                fields.push(key)
            }
        }

        for(let i=0;i<indications.length; i++){
            if(indications[i].tasks){
                for(let j=0;j<indications[i].tasks.length; j++){
                    if(indications[i].tasks[j].done !== null){
                        indications[i][`${indications[i].tasks[j].day}`] = indications[i].tasks[j].done
                    }else{
                        indications[i][`${indications[i].tasks[j].day}`] = ' '
                    }
                }
            }
        }
        
        
        const ws = createWs(
            indications,
            fields,
            titles,
            columns.days,
            data1.content.headings.head,
            count1, count2
        )
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
        setLoading2(false)
    }

    return (
        <>
            <div className={loading2 ? "progress-container" : "progress-container-none"}>
                <h2 id="rdjugvd" className="progress-text">Запрос на получение данных отправлен</h2>
            </div>
            {loading2 && 
                <Preloader></Preloader>}
            <HeadingWithButtons>
                <h1 className="headline-4">{t('pages.tasks.title')} - {statusText}</h1>
            </HeadingWithButtons>
            <TaskStatisticsFilter setFilterData={setFilterData} params={params} exportToCSV={exportToCSV}></TaskStatisticsFilter>
            <MonthFilter setFilterData={setFilterData} params={params}></MonthFilter>
            <GridCal>
                    {
                        tableConfig.map((item, index) =>
                            <DivHead key={index}>{item}</DivHead>
                        )}
                    {arr &&
                        <>
                        {arr.map((a, index)=>
                             <DivElem key={index} $none></DivElem>   
                        )}
                        {items.map((item, index) => 
                            <DivElem key={index+1} >
                                    <span>{index+1}</span>
                                    {item.users.map((user, i) =>
                                        <p key={i}
                                            onClick={() => {openModal(item.date, user.user.id)}}
                                            className={
                                                (user.done === null || user.done === 0) ? "not-done" : 
                                                user.done < user.plan ? "" :
                                                (user.plan === null || user.plan === 0) ? "frozen" :
                                                "done"
                                            }
                                        >
                                            {user.user.name}
                                        </p>
                                    )}
                            </DivElem>
                        )}
                        </>
                    }
            </GridCal>
            {visible && <TaskModal items={items} userId = {userId} params2={params2} setParams2={setParams2} setVisible={setVisible}></TaskModal>}
        </>
    );
};

export default TasksStatics;