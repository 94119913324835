import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Dictionaries from "../../../../services/admin/dictionaries";
import {toast} from "react-toastify";
import CustomSelect from "../../../../components/CustomSelect";

const TaskControllerFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, control, setValue, watch, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null, phone: null});
        setFilterData(Utils.objectToSearchParams({name: '', phone: ''}))
    }

    const cityId = watch('city_id', null);
    const companyId = watch('company_id', null);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [sectors, setSectors] = useState();
    const [sectorsLoading, setSectorsLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (companyId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchSectors = useCallback(async () => {
        setValue('sector_id', null);

        if (companyId && cityId) {
            setSectorsLoading(true);

            const res = await Dictionaries.sectors({queryString: `city_id=${cityId}&company_id=${companyId}`});

            if (res.statusCode === 200) {
                setSectors(res.content);
            } else {
                toast.error(res.message)
            }

            setSectorsLoading(false);
        }
    }, [setValue, companyId, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchSectors();
    }, [fetchSectors]);

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.fullName')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number')}>
                        <Input defaultValue={params.get('phone')} $small type="text" {...register('phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.email')}>
                        <Input defaultValue={params.get('email')} $small type="email" {...register('email')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={companies} onChange={onChange}
                                                    value={value}
                                                    isLoading={companiesLoading}
                                                    isDisabled={companiesLoading}

                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={cities} onChange={onChange}
                                                    value={value}
                                                    isLoading={(!!companyId) ? citiesLoading : false}
                                                    isDisabled={!!!companyId || citiesLoading}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.plot')}>
                        <Controller name="sector_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={sectors} onChange={onChange}
                                                    value={value}
                                                    isLoading={(!!companyId && !!cityId) ? sectorsLoading : false}
                                                    isDisabled={!!!companyId || !!!cityId || sectorsLoading}/>
                                    )}/>
                    </FormGroup>
                </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default TaskControllerFilter;