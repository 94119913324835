import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../components/Input";
import Dictionaries from "../../../../services/admin/dictionaries";
import Button from "../../../../components/Button";
import CustomSelect from "../../../../components/CustomSelect";
import {toast} from "react-toastify";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Checkbox from "../../../../components/Checkbox";

const BankbooksFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, watch, setValue, control, reset} = useForm();

    const companyId = watch('company_id', null)
    const cityId = watch('city_id', null);
    const districtId = watch('district_id', null)
    const microdistrictId = watch('microdistrict_id', null)
    const streetId = watch('street_id', null);
    const houseId = watch('house_id', null);
    const apartmentId = watch('apartment_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const [microdistricts, setMicrodistricts] = useState();
    const [microdistrictsLoading, setMicrodistrictsLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const [apartments, setApartments] = useState();
    const [apartmentsLoading, setApartmentsLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        setCitiesLoading(true);

        const res = companyId ? await Dictionaries.cities({queryString: `company_id=${companyId}`}) : await Dictionaries.cities();

        if (res.statusCode === 200) {
            setCities(res.content);

            if (!res.content.some(item => item.value === cityId)) {
                setValue('city_id', null)
            }
        } else {
            toast.error(res.message)
        }

        setCitiesLoading(false);

    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchMicrodistricts = useCallback(async () => {
        if (districtId) {
            setMicrodistrictsLoading(true);

            const res = await Dictionaries.microdistricts({queryString: `district_id=${districtId}`});

            if (res.statusCode === 200) {
                setMicrodistricts(res.content);

                if (!res.content.some(item => item.value === microdistrictId)) {
                    setValue('microdistrict_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setMicrodistrictsLoading(false);
        } else {
            setValue('microdistrict_id', null)
        }
    }, [setValue, districtId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchStreets = useCallback(async () => {
        if (districtId) {
            setStreetsLoading(true);

            const microdistrictQuery = microdistrictId ? `&microdistrict_id=${microdistrictId}` : "";

            const res = await Dictionaries.streets({queryString: `district_id=${districtId}` + microdistrictQuery});

            if (res.statusCode === 200) {
                setStreets(res.content);

                if (!res.content.some(item => item.value === streetId)) {
                    setValue('street_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        } else {
            setValue('street_id', null)
        }
    }, [setValue, districtId, microdistrictId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchHouses = useCallback(async () => {
        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);

                if (!res.content.some(item => item.value === houseId)) {
                    setValue('house_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        } else {
            setValue('house_id', null)
        }
    }, [setValue, streetId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchApartments = useCallback(async () => {
        if (houseId) {
            setApartmentsLoading(true);

            const res = await Dictionaries.apartments({queryString: `house_id=${houseId}`});

            if (res.statusCode === 200) {
                setApartments(res.content);

                if (!res.content.some(item => item.value === apartmentId)) {
                    setValue('apartment_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setApartmentsLoading(false);
        } else {
            setValue('apartment_id', null)
        }
    }, [setValue, houseId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    useEffect(() => {
        fetchMicrodistricts();
    }, [fetchMicrodistricts]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    useEffect(() => {
        fetchApartments();
    }, [fetchApartments]);

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({
            name: null,
            number: null
        });
        setFilterData(Utils.objectToSearchParams({
            name: '',
            number: ''
        }))
    }

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.fullName')}>
                        <Input $small defaultValue={params.get('name')} type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.account_number')}>
                        <Input $small defaultValue={params.get('number')} type="text" {...register('number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.device_number')}>
                        <Input $small defaultValue={params.get('device_number')} type="text" {...register('device_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.subscriber_type')}>
                        <Controller defaultValue={params.get('type')} name="type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookUserTypes}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.segment')}>
                        <Controller defaultValue={params.get('sector')} name="sector" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookSectorTypes}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={companies} onChange={onChange}
                                                    value={value}
                                                    isLoading={companiesLoading}
                                                    isDisabled={companiesLoading}

                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={cities} onChange={onChange}
                                                    value={value}
                                                    isLoading={citiesLoading}
                                                    isDisabled={citiesLoading}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.district')}>
                        <Controller name="district_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={districts} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!cityId ? districtsLoading : false}
                                                    isDisabled={!!!cityId || districtsLoading}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.microdistrict')}>
                        <Controller name="microdistrict_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={microdistricts} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!districtId ? microdistrictsLoading : false}
                                                    isDisabled={!!!districtId || microdistrictsLoading}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.street')}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={streets} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!districtId ? streetsLoading : false}
                                                    isDisabled={!!!districtId || streetsLoading}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.house')}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={houses} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!streetId ? housesLoading : false}
                                                    isDisabled={!!!streetId || housesLoading}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.apartment')}>
                        <Controller name="apartment_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={apartments} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!houseId ? apartmentsLoading : false}
                                                    isDisabled={!!!houseId || apartmentsLoading}
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.indication_status')}>
                        <Controller defaultValue={params.get('indication_status')} name="indication_status" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.indicationStatuses}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup style={{alignSelf: "center"}}>
                        <Checkbox {...register('device_expired')}>{t('labels.device_expired')}</Checkbox>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default BankbooksFilter;