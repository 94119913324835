import React, {useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../components/Input";
import Dictionaries from "../../../../services/callOperator/dictionaries";
import Button from "../../../../components/Button";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Checkbox from "../../../../components/Checkbox";

const DevicesFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, control, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({
            number: null,
            device_number: null,
            name: null,
            manufacturer: null,
            manufacture_date: null,
            last_check: null
        });
        setFilterData(Utils.objectToSearchParams({
            number: '',
            device_number: '',
            name: '',
            manufacturer: '',
            manufacture_date: '',
            last_check: ''
        }))
    }

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.device_number')}>
                        <Input $small defaultValue={params.get('device_number')} type="text" {...register('device_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.device_type')}>
                        <Controller defaultValue={params.get('type')} name="type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.resourceTypes}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.account_number')}>
                        <Input $small defaultValue={params.get('number')} type="text" {...register('number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.consumer_fullName')}>
                        <Input $small defaultValue={params.get('name')} type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller defaultValue={params.get('company_id')} name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.companies}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacturer')}>
                        <Input $small defaultValue={params.get('manufacturer')} type="text" {...register('manufacturer')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.manufacture_date')}>
                        <Input $small defaultValue={params.get('manufacture_date')} type="date" {...register('manufacture_date')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.last_check')}>
                        <Input $small defaultValue={params.get('last_check')} type="date" {...register('last_check')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.indication_status')}>
                        <Controller defaultValue={params.get('indication_status')} name="indication_status" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.indicationStatuses}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup style={{alignSelf: "center"}}>
                        <Checkbox {...register('expired')}>{t('labels.device_expired')}</Checkbox>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default DevicesFilter;