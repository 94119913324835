import React from 'react';
import routePaths from "./routePaths";
import generalRoutePaths from "../general/routePaths";
import DefaultLayout from "../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch} from "react-router-dom";
import LabAdministrators from "../../pages/laboratoryAdministrator/Users/LabAdministrators";
import LabAdministratorCreate from "../../pages/laboratoryAdministrator/Users/LabAdministrators/LabAdministratorCreate";
import LabAdministratorEdit from "../../pages/laboratoryAdministrator/Users/LabAdministrators/LabAdministratorEdit";
import Profile from "../../pages/general/Profile";
import LabSpecialists from "../../pages/laboratoryAdministrator/Users/LabSpecialists";
import LabSpecialistCreate from "../../pages/laboratoryAdministrator/Users/LabSpecialists/LabSpecialistCreate";
import LabSpecialistEdit from "../../pages/laboratoryAdministrator/Users/LabSpecialists/LabSpecialistEdit";
import LaboratoryEdit from "../../pages/laboratoryAdministrator/Laboratory/LaboratoryEdit";
import Applications from "../../pages/laboratoryAdministrator/Applications";
import Application from "../../pages/laboratoryAdministrator/Applications/Application";
import Invoices from "../../pages/laboratoryAdministrator/Invoices";
import {useAuth} from "../Auth/ProvideAuth";
import {useLangContext} from "../../i18n/ProvideLang";
import ApplicationCreate from "../../pages/laboratoryAdministrator/Applications/ApplicationCreate";

const Routes = () => {
    const {t} = useTranslation();

    const {userData} = useAuth()
    const {lang} = useLangContext()

    const sidebarItems = [
        {
            title: userData?.laboratory[`name_${lang}`],
            url: routePaths.laboratory.index,
            icon: "physics"
        },
        {
            title: t('sidebar.users.title'),
            url: routePaths.users.index,
            icon: "users",
            children: [
                {
                    title: t('sidebar.users.children.administrators'),
                    url: routePaths.users.children.lab_administrators.index
                },
                {
                    title: t('sidebar.users.children.specialists'),
                    url: routePaths.users.children.lab_specialists.index
                }
            ]
        },
        {
            title: t('sidebar.applications.title'),
            url: routePaths.applications.index,
            icon: "list",
            children: [
                {
                    title: t('sidebar.applications.children.all'),
                    url: routePaths.applications.children.all.index
                },
                {
                    title: t('sidebar.applications.children.under_consideration'),
                    url: routePaths.applications.children.under_consideration.index
                },
                {
                    title: t('sidebar.applications.children.rejected'),
                    url: routePaths.applications.children.rejected.index
                },
                {
                    title: t('sidebar.applications.children.awaiting_payment'),
                    url: routePaths.applications.children.awaiting_payment.index
                },
                {
                    title: t('sidebar.applications.children.awaiting_completion'),
                    url: routePaths.applications.children.awaiting_completion.index
                },
                {
                    title: t('sidebar.applications.children.in_work'),
                    url: routePaths.applications.children.in_work.index
                },
                {
                    title: t('sidebar.applications.children.cancelled'),
                    url: routePaths.applications.children.cancelled.index
                },
                {
                    title: t('sidebar.applications.children.done'),
                    url: routePaths.applications.children.done.index
                }
            ]
        },
        {
            title: t('sidebar.invoices.title'),
            url: routePaths.invoices.index,
            icon: "receipt"
        }
    ]

    return (
        <DefaultLayout sidebarItems={sidebarItems}>
            <Switch>
                <Redirect exact from={routePaths.home.index} to={routePaths.laboratory.index}/>
                {/*Profile*/}
                <Route exact path={generalRoutePaths.profile.index} component={Profile}/>
                {/*Users*/}
                <Redirect exact from={routePaths.users.index} to={routePaths.users.children.lab_administrators.index}/>
                {/*Users - Administrators*/}
                <Route exact path={routePaths.users.children.lab_administrators.index} component={LabAdministrators}/>
                <Route exact path={routePaths.users.children.lab_administrators.create} component={LabAdministratorCreate}/>
                <Route exact path={routePaths.users.children.lab_administrators.edit()} component={LabAdministratorEdit}/>
                {/*Users - Specialists*/}
                <Route exact path={routePaths.users.children.lab_specialists.index} component={LabSpecialists}/>
                <Route exact path={routePaths.users.children.lab_specialists.create} component={LabSpecialistCreate}/>
                <Route exact path={routePaths.users.children.lab_specialists.edit()} component={LabSpecialistEdit}/>
                {/*Laboratory*/}
                <Route path={routePaths.laboratory.index} component={LaboratoryEdit}/>
                {/*Applications*/}
                <Redirect exact from={routePaths.applications.index} to={routePaths.applications.children.all.index}/>
                <Route path={routePaths.applications.create} component={ApplicationCreate}/>
                <Route exact path={routePaths.applications.index} component={Applications}/>
                <Route exact path={routePaths.applications.children.all.index}>
                    <Applications key={routePaths.applications.children.all.index}
                                  statusText={t('sidebar.applications.children.all')}/>
                </Route>
                <Route exact path={routePaths.applications.children.under_consideration.index}>
                    <Applications key={routePaths.applications.children.under_consideration.index} statusId="1"
                                 statusText={t('sidebar.applications.children.under_consideration')}/>
                </Route>
                <Route exact path={routePaths.applications.children.rejected.index}>
                    <Applications key={routePaths.applications.children.rejected.index} statusId="2"
                                  statusText={t('sidebar.applications.children.rejected')}/>
                </Route>
                <Route exact path={routePaths.applications.children.awaiting_payment.index}>
                    <Applications key={routePaths.applications.children.awaiting_payment.index} statusId="3"
                                  statusText={t('sidebar.applications.children.awaiting_payment')}/>
                </Route>
                <Route exact path={routePaths.applications.children.awaiting_completion.index}>
                    <Applications key={routePaths.applications.children.awaiting_completion.index} statusId="4"
                                  statusText={t('sidebar.applications.children.awaiting_completion')}/>
                </Route>
                <Route exact path={routePaths.applications.children.in_work.index}>
                    <Applications key={routePaths.applications.children.in_work.index} statusId="5"
                                  statusText={t('sidebar.applications.children.in_work')}/>
                </Route>
                <Route exact path={routePaths.applications.children.cancelled.index}>
                    <Applications key={routePaths.applications.children.cancelled.index} statusId="6"
                                  statusText={t('sidebar.applications.children.cancelled')}/>
                </Route>
                <Route exact path={routePaths.applications.children.done.index}>
                    <Applications key={routePaths.applications.children.done.index} statusId="7"
                                  statusText={t('sidebar.applications.children.done')}/>
                </Route>
                <Route path={routePaths.applications.detail.index()} component={Application}/>
                {/*Invoices*/}
                <Route exact path={routePaths.invoices.index} component={Invoices}/>
            </Switch>
        </DefaultLayout>
    );
};

export default Routes;