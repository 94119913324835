import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Utils from "../../services/utils";
import FormGroup from "../FormGroup";
import Input from "../Input";
import Button from "../Button";

const NameFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null, name_kk: null, name_ru: null});
        setFilterData(Utils.objectToSearchParams({name: '', name_kk: '', name_ru: ''}))
    }

    const open = () => {
        setIsOpened(!isOpened)
    }


    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.name_kk')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name_kk')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name_ru')}/>
                    </FormGroup>
                </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default NameFilter;