import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/laboratoryAdministrator/routePaths";
import useFetchItems from "../../../hooks/useFetchItems";
import SecondaryLink from "../../../components/SecondaryLink";
import WhiteBlock from "../../../components/WhiteBlock";
import ItemsTable from "../../../components/ItemsTable";
import Pagination from "../../../components/Pagination";
import TablePreloader from "../../../components/TablePreloader";
import ApplicationsServices from "../../../services/laboratoryAdministrator/applicationsServices";
import {useParams} from "react-router-dom";
import {useInfo} from "./Application";
import Button from '../../../components/Button';
import ApproveModal from './components/ApproveModal';

const ApplicationSpecialists = () => {
    const {id} = useParams();
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();

    const {info, fetchInfo} = useInfo()

    const [approveModalIsOpen, setApproveModalIsOpen] = useState();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.applications.index,
                title: t('sidebar.applications.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info.number
            },
            {
                path: null,
                title: t('labels.specialists')
            }
        ])
    }, [setBreadcrumbs, t, info])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        fetchItems
    } = useFetchItems(ApplicationsServices.specialists.index, null, {id})

    const [formattedItems, setFormattedItems] = useState();

    useEffect(() => {
        if (items) {
            const newItems = items.map(({id, name, phone, email}) => {
                return {
                    id,
                    fields: [
                        id,
                        name,
                        (phone && <SecondaryLink href={`tel: ${phone}`}>{phone}</SecondaryLink>),
                        (email && <SecondaryLink href={`mailto: ${email}`}>{email}</SecondaryLink>)
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.fullName'), t('labels.phone_number'), t('labels.email'), ''],
        sizes: ['10%', '30%', '25%', '25%', '']
    }

    return (
        <WhiteBlock>
            {((info?.status?.id === 1) || (info?.status?.id === 3) || (info?.status?.id === 5)) && <div className="row row--multiline justify-end">
                <div className="col-auto">
                    <Button $small $customColor={'#1FA2FF'} $withIcon variant="outline" onClick={() => setApproveModalIsOpen(true)}><i className="icon-clip"/><span>{t('labels.attach')}</span></Button>
                </div>
            </div>}
            {formattedItems
                ? <>
                    <ItemsTable
                        {...{config: tableConfig, fetchItems, loading}}
                        items={formattedItems}
                        removeMethod={((info?.status?.id === 1) || (info?.status?.id === 3) || (info?.status?.id === 5)) && ApplicationsServices.specialists.detach}
                        parentId={id}
                    />
                    <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                </>
                : <TablePreloader/>}
            <ApproveModal
                isOpen={approveModalIsOpen}
                closeModal={() => setApproveModalIsOpen(false)}
                onRequestClose={() => setApproveModalIsOpen(false)}
                successCallback={() => {
                    setApproveModalIsOpen(false);
                    fetchItems();
                }}
                method={ApplicationsServices.specialists.attach}
                id={id}
            />
        </WhiteBlock>
    );
};

export default ApplicationSpecialists;