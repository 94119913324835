import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Dictionaries from "../../../../services/operator/dictionaries";
import {toast} from "react-toastify";
import CustomSelect from "../../../../components/CustomSelect";

const TaskSealersFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, control, setValue, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null, phone: null, email: null, company_id: null});
        setFilterData(Utils.objectToSearchParams({name: '', phone: '', email: '', company_id: ''}))
    }

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.fullName')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.phone_number')}>
                        <Input defaultValue={params.get('phone')} $small type="text" {...register('phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.email')}>
                        <Input defaultValue={params.get('email')} $small type="email" {...register('email')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={companies} onChange={onChange}
                                                    value={value}
                                                    isLoading={companiesLoading}
                                                    isDisabled={companiesLoading}

                                        />
                                    )}
                        />
                    </FormGroup>
                </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default TaskSealersFilter;