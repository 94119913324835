import React, {useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../services/callOperator/dictionaries";

const ApplicationsFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, reset, control} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null, phone: null});
        setFilterData(Utils.objectToSearchParams({name: '', phone: ''}))
    }

    const open = () => {
        setIsOpened(!isOpened)
    }
    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.application_number')}>
                        <Input defaultValue={params.get('number')} $small type="text" {...register('number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.status')}>
                        <Controller defaultValue={params.get('status_id')} name="status_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.applicationStatuses}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.service_name')}>
                        <Input defaultValue={params.get('service_name')} $small type="text" {...register('service_name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.account_number')}>
                        <Input defaultValue={params.get('bankbook_number')} $small type="text" {...register('bankbook_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.subscriber_fullName')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.laboratory')}>
                        <Controller defaultValue={params.get('laboratory_id')} name="laboratory_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.laboratories}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.period')}>
                        <Input $small defaultValue={params.get('from')} type="date" {...register('from')}/>
                        <div className="mb-10"/>
                        <Input $small defaultValue={params.get('to')} type="date" {...register('to')}/>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default ApplicationsFilter;