const routePaths = {
    home: {
        index: "/"
    },
    users: {
        index: "/users",
        children: {
            all: {
                index: "/users/all"
            },
            lab_administrators: {
                index: "/users/administrators",
                create: "/users/administrators/create",
                edit: (id) => `/users/administrators/${id ? id : ":id"}/edit`
            },
            lab_specialists: {
                index: "/users/specialists",
                create: "/users/specialists/create",
                edit: (id) => `/users/specialists/${id ? id : ":id"}/edit`
            }
        }
    },
    laboratory: {
        index: `/laboratory`,
        information: `/laboratory/information`,
        services: `/laboratory/services`,
        administrators: `/laboratory/administrators`,
        specialists: `/laboratory/specialists`,
        cities: `/laboratory/cities`
    },
    applications: {
        index: `/applications`,
        create: '/applications/create',
        detail: {
            index: (id) => `/applications/${id ? id : ":id"}`,
            information: (id) => `/applications/${id ? id : ":id"}/information`,
            services: (id) => `/applications/${id ? id : ":id"}/services`,
            specialists: (id) => `/applications/${id ? id : ":id"}/specialists`,
            history: (id) => `/applications/${id ? id : ":id"}/history`
        },
        children: {
            all: {
                index: "/applications/all"
            },
            under_consideration: {
                index: '/applications/under-consideration'
            },
            rejected: {
                index: '/applications/rejected'
            },
            awaiting_payment: {
                index: '/applications/awaiting-payment'
            },
            awaiting_completion: {
                index: '/applications/awaiting-completion'
            },
            in_work: {
                index: '/applications/in-work'
            },
            cancelled: {
                index: '/applications/cancelled'
            },
            done: {
                index: '/applications/done'
            },
        }
    },
    invoices: {
        index: `/invoices`,
        services: `/invoices/services`
    }
}

export default routePaths;