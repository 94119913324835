import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Button from "../../../../components/Button";
import {toast} from "react-toastify";
import CustomSelect from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";
import Dictionaries from "../../../../services/operator/dictionaries";
import {useAuth} from "../../../../navigation/Auth/ProvideAuth";

const SectorsFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, control, setValue, watch, register, reset} = useForm();

    const {userData} = useAuth();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({
            name: null,
            city_id: null,
            street_id: null,
            house_id: null,
            controller: null
        });
        setFilterData(Utils.objectToSearchParams({
            name: '',
            city_id: '',
            street_id: '',
            house_id: '',
            controller: ''
        }))
    }

    const cityId = watch('city_id', null);
    const streetId = watch('street_id', null);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const fetchCities = useCallback(async () => {
        setCitiesLoading(true);

        const res = await Dictionaries.cities({queryString: `company_id=${userData.company_id}`});

        if (res.statusCode === 200) {
            setCities(res.content);
        } else {
            toast.error(res.message)
        }

        setCitiesLoading(false);
    }, [userData]);

    const fetchStreets = useCallback(async () => {
        setValue('street_id', null);
        setValue('house_id', null);
        setHouses(null)

        if (cityId) {
            setStreetsLoading(true);

            const res = await Dictionaries.streets({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setStreets(res.content);
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        }
    }, [setValue, cityId]);

    const fetchHouses = useCallback(async () => {
        setValue('house_id', null);

        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        }
    }, [setValue, streetId]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.name')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={cities} onChange={onChange}
                                                    value={value}
                                                    isLoading={citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.street')}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={streets} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!cityId ? streetsLoading || citiesLoading : false} isDisabled={!!!cityId || streetsLoading || citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.house')}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={houses} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!streetId ? housesLoading || streetsLoading || citiesLoading : false} isDisabled={!!!streetId || housesLoading || streetsLoading || citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.controller')}>
                        <Input defaultValue={params.get('controller')} $small type="text" {...register('controller')}/>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default SectorsFilter;