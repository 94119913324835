import React, {useState, useCallback, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import { useForm, Controller} from "react-hook-form";
import Utils from "../../../../services/utils";
import FormGroup from "../../../../components/FormGroup";
import Button from "../../../../components/Button";
import Dictionaries from '../../../../services/sealerOperator/dictionaries';
import {toast} from "react-toastify";
import CustomSelect from "../../../../components/CustomSelect";
import CustomSelect2 from "../../../../components/CustomSelect2";
import Dropdown from '../../../../components/Dropdown';
import DropdownContent from '../../../../components/DropdownContent';


const TaskStatisticsFilter = ({setFilterData, params, exportToCSV}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);
    const query = new URLSearchParams(window.location.search);

    const {handleSubmit, control, setValue, watch, reset} = useForm();

    const handleReset = (e) => {
        e.preventDefault();
        reset({company_id: null, users: null});
        setFilterData(Utils.objectToSearchParams({company_id: '', users: ''}))
    }

    const companyId = watch('company_id', null);
    const cityId = watch('city_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [controllers, setSontrollers] = useState();
    const [controllersLoading, setSontrollersLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchControllers = useCallback(async () => {
        setSontrollers(null)

        if (companyId) {
            setSontrollersLoading(true);

            const res = await Dictionaries.users.companyControllers({queryString: `company_id=${companyId}`});

            if (res.statusCode === 200) {
                setSontrollers(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('users', null)
                }
            } else {
                toast.error(res.message)
            }

            setSontrollersLoading(false);
        } else {
            setValue('users', null)
        }
    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = (data) => {
        const {...rest} = data;

        const newData = {
            ...rest,
            date: query.get('date') && query.get('date')
        }
        setFilterData(Utils.objectToSearchParams(newData))
    };

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchControllers();
    }, [fetchControllers]);


    const open = () => {
        setIsOpened(!isOpened)
    }


    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
                <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                    <div className="filter-wrapper2">
                        <FormGroup label={t('labels.resource_company')}>
                            <Controller name="company_id" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect $small options={companies} onChange={onChange}
                                                        value={value}
                                                        isLoading={companiesLoading}
                                                        isDisabled={companiesLoading}

                                            />
                                        )}
                            />
                        </FormGroup>
                        <FormGroup label={t('labels.controller')}>
                            <Controller name="users" control={control}
                                        render={({field: {onChange, value}}) => (
                                            <CustomSelect2 isMulti $small options={controllers} onChange={onChange}
                                                        value={value}
                                                        isLoading={(!!companyId) ? controllersLoading : false}
                                                        isDisabled={!!!companyId || controllersLoading}
                                            />
                                        )}
                            />
                        </FormGroup>
                    </div>
                    <div className="row row--multiline justify-end">
                        <div className="col-auto">
                            <Button  $small $fluid>{t('labels.apply')}</Button>
                        </div>
                        <div className="col-auto">
                            <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                        </div>
                        <div className="col-auto">
                            <Dropdown title={
                                <div className='btn-outline'>
                                    <i className="icon-download"/>
                                    <span>{t('labels.export')}</span>
                                </div>
                                } menuPosition="center">
                                <DropdownContent style={{width: "100%"}} className='dropdown-list'>
                                    <ul className='dropdown-list-ul'>
                                        <li onClick={() => exportToCSV('tasks', 7, 4)}>Статистика выполнения задач</li>
                                        <li onClick={() => exportToCSV('acts', 7, 4)}>Статистика формирование актов (общая)</li>
                                        <li onClick={() => exportToCSV('act', 2, 2)}>Статистика формирование актов (детальная)</li>
                                    </ul>
                                </DropdownContent>
                            </Dropdown>
                        </div>
                    </div>
                </form>
            }
            
        </div>
    );
};

export default TaskStatisticsFilter;