import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Button from "../../../../components/Button";
import Dictionaries from "../../../../services/minAdmin/dictionaries";
import {toast} from "react-toastify";
import CustomSelect from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";

const SectorsFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, control, setValue, watch, register, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({company_id: null, city_id: null, street_id: null, house_id: null, controller: null });
        setFilterData(Utils.objectToSearchParams({company_id: '', city_id: '', street_id: '', house_id: '', controller:''}))
    }

    const companyId = watch('company_id', null)
    const cityId = watch('city_id', null);
    const streetId = watch('street_id', null);
    const houseId = watch('house_id', null);

    const [companies, setCompanies] = useState();
    const [companiesLoading, setCompaniesLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const fetchCompanies = useCallback(async () => {
        setCompaniesLoading(true);

        const res = await Dictionaries.companies();

        if (res.statusCode === 200) {
            setCompanies(res.content);
        } else {
             toast.error(res.message)
        }

        setCompaniesLoading(false);
    }, [setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchCities = useCallback(async () => {
        setCities(null)

        setCitiesLoading(true);

        const res = companyId ? await Dictionaries.cities({queryString: `company_id=${companyId}`}) : await Dictionaries.cities();

        if (res.statusCode === 200) {
            setCities(res.content);

            if (!res.content.some(item => item.value === cityId)) {
                setValue('city_id', null)
            }
        } else {
            toast.error(res.message)
        }

        setCitiesLoading(false);

    }, [setValue, companyId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchStreets = useCallback(async () => {
        setStreets(null)

        if (cityId) {
            setStreetsLoading(true);

            const res = await Dictionaries.streets({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setStreets(res.content);

                if (!res.content.some(item => item.value === streetId)) {
                    setValue('street_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        } else {
            setValue('street_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchHouses = useCallback(async () => {
        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);

                if (!res.content.some(item => item.value === houseId)) {
                    setValue('house_id', null);
                }
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        } else {
            setValue('house_id', null);
        }
    }, [setValue, streetId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);


    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.name')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={companies} onChange={onChange}
                                                    value={value}
                                                    isLoading={companiesLoading}
                                                    isDisabled={companiesLoading}

                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={cities} onChange={onChange}
                                                    value={value}
                                                    isLoading={citiesLoading}
                                                    isDisabled={citiesLoading}
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.street')}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={streets} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!cityId ? streetsLoading || citiesLoading : false} isDisabled={!!!cityId || streetsLoading || citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.house')}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={houses} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!streetId ? housesLoading || streetsLoading || citiesLoading : false} isDisabled={!!!streetId || housesLoading || streetsLoading || citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.controller')}>
                        <Input defaultValue={params.get('controller')} $small type="text" {...register('controller')}/>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default SectorsFilter;