import React, {useCallback, useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../../layouts/DefaultLayout";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import routePaths from "../../../../navigation/operator/routePaths";
import useFetchInfo from "../../../../hooks/useFetchInfo.hook";
import AddressesServices from "../../../../services/operator/addressesServices";
import useUpdate from "../../../../hooks/useUpdate.hook";
import WhiteBlock from "../../../../components/WhiteBlock";
import FormGroup from "../../../../components/FormGroup";
import CustomSelect from "../../../../components/CustomSelect";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import ConfirmActionModal from "../../../../components/modals/ConfirmActionModal";
import Dictionaries from "../../../../services/operator/dictionaries";
import {toast} from "react-toastify";
import {useLangContext} from "../../../../i18n/ProvideLang";
import InputMessage from "../../../../components/InputMessage";

const MicrodistrictEditInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {id} = useParams();
    const {t} = useTranslation();
    const {handleSubmit, register, control, watch, setValue, formState: {errors}} = useForm();
    const history = useHistory();

    const {state} = useLocation();

    const {info, loading: fetchLoading} = useFetchInfo(AddressesServices.microdistricts.view, id);
    const {lang} = useLangContext();

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.addresses.index,
                title: t('sidebar.addresses.title')
            },
            {
                path: routePaths.addresses.children.microdistricts.index,
                title: t('sidebar.addresses.children.microdistricts')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info[`name_${lang}`]
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info, lang])

    

    const regionId = watch('region_id', null);
    const cityId = watch('city_id', null);
    const districtId = watch('district_id', null)

    const [regions, setRegions] = useState();
    const [regionsLoading, setRegionsLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const fetchRegions = useCallback(async () => {
        setRegionsLoading(true);
        const res = await Dictionaries.regions();

        if (res.statusCode === 200) {
            setRegions(res.content);
        } else {
            toast.error(res.message)
        }
        setRegionsLoading(false);
    }, []);

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (regionId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `region_id=${regionId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, regionId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchRegions();
    }, [fetchRegions]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    useEffect(() => {
        const {region_id, city_id, district_id} = {...state};
        region_id && setValue('region_id', String(region_id));
        city_id && setValue('city_id', String(city_id))
        district_id && setValue('district_id', String(district_id))
    }, [state, setValue])

    const {onSubmit, loading} = useUpdate(AddressesServices.microdistricts.update, id, false);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();

    const handleRemove = (e) => {
        e.preventDefault();
        setRemoveModalIsOpen(true);
    }

    useEffect(() => {
        if (info) {
            const {region_id, city_id, district_id, ...rest} = info;

            for (const [key, value] of Object.entries(rest)) {
                if (key) setValue(key, value);
            }

            region_id && setValue('region_id', String(region_id));
            city_id && setValue('city_id', String(city_id));
            district_id && setValue('district_id', String(district_id))
        }
    }, [info, setValue])

    return (
        <>
            <WhiteBlock>
                <form onSubmit={handleSubmit(onSubmit)} className={loading || fetchLoading ? "isLoading" : ""}>
                    <FormGroup label={t('labels.region') + " *"}>
                        <Controller name="region_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={regions} onChange={onChange}
                                                      value={value}
                                                      isLoading={regionsLoading}
                                                      isDisabled={state?.region_id}
                                                      $error={errors.region_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.region_id && errors.region_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.city') + " *"}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!regionId ? citiesLoading : false}
                                                      isDisabled={!!!regionId || citiesLoading || state?.city_id}
                                                      $error={errors.city_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.city_id && errors.city_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.district') + " *"}>
                        <Controller name="district_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={districts} onChange={onChange}
                                                      value={value}
                                                      isLoading={!!cityId ? districtsLoading : false}
                                                      isDisabled={!!!cityId || districtsLoading || state?.district_id}
                                                      $error={errors.district_id}
                                        />
                                    )}
                                    rules={{required: true}}
                        />
                        {errors.district_id && errors.district_id.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_kk') + " *"}>
                        <Input type="text" {...register('name_kk', {required: true})} $error={errors.name_kk}/>
                        {errors.name_kk && errors.name_kk.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <FormGroup label={t('labels.name_ru') + " *"}>
                        <Input type="text" {...register('name_ru', {required: true})} $error={errors.name_ru}/>
                        {errors.name_ru && errors.name_ru.type === 'required' &&
                        <InputMessage $error>{t('messages.required')}</InputMessage>}
                    </FormGroup>
                    <div className="row row--multiline justify-end">
                        {/*<div className="col-auto">
                            <Button onClick={handleRemove} $red>{t('labels.remove')}</Button>
                        </div>*/}
                        <div className="col-auto">
                            <Button>{t('labels.save')}</Button>
                        </div>
                    </div>
                </form>
            </WhiteBlock>
            {/*<ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    history.goBack();
                }}
                method={AddressesServices.microdistricts.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />*/}
        </>
    );
};

export default MicrodistrictEditInformation;