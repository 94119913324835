import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import ModalComponent from '../../../../components/ModalComponent';
import { Carousel } from 'antd';

const ImagesModal = ({method, closeModal, imagesList, setErrorMsg, content, data, green, parentId, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{maxWidth: 500}}>
                <Carousel draggable={true} arrows={true}>
                    {imagesList?.map((card, index) =>
                        <img src={card} key={index}/>
                    )}
                </Carousel>
            </div>
        </ModalComponent>
    );
};

export default ImagesModal;