import React from 'react';
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import Utils from "../../../../../services/utils";
import FormGroup from "../../../../../components/FormGroup";
import Input from "../../../../../components/Input";
import CustomAsyncSelect from "../../../../../components/CustomAsyncSelect";
import Dictionaries from "../../../../../services/minAdmin/dictionaries";
import Button from "../../../../../components/Button";

const BankbooksFilter = ({setFilterData, params, fetchLoading, cityId}) => {
    const {t} = useTranslation();

    const {handleSubmit, register, control, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null});
        setFilterData(Utils.objectToSearchParams({name: ''}))
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                <FormGroup label={t('labels.number')}>
                    <Input defaultValue={params.get('number')} $small type="text" {...register('number')}/>
                </FormGroup>
                <FormGroup label={t('labels.resource_company')}>
                    {!fetchLoading && <Controller defaultValue={params.get('company_id')} name="company_id" control={control}
                                                  render={({field: {onChange, value}}) => (
                                                      <CustomAsyncSelect
                                                          method={Dictionaries.companies}
                                                          data={{queryString: `city_id=${cityId}`}}
                                                          onChange={onChange}
                                                          value={value}
                                                          $small
                                                      />
                                                  )}/>}
                </FormGroup>
                <div className="mb-20">
                    <Button $small $fluid>{t('labels.apply')}</Button>
                </div>
                <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
            </form>
        </>
    );
};

export default BankbooksFilter;