import React, {useEffect, useState} from 'react';
import {useBreadCrumbs} from "../../../layouts/DefaultLayout";
import {useTranslation} from "react-i18next";
import routePaths from "../../../navigation/admin/routePaths";
import {useInfo} from "./Application";
import WhiteBlock from "../../../components/WhiteBlock";
import FormGroup from "../../../components/FormGroup";
import ApplicationsServices from '../../../services/admin/applicationsServices';
import Button from '../../../components/Button';
import TextArea from '../../../components/TextArea';
import { toast } from 'react-toastify';
import {useParams} from "react-router-dom";

const ApplicationInformation = () => {
    const {setBreadcrumbs} = useBreadCrumbs();
    const {t} = useTranslation();
    const [description, setDescription] = useState('');

    const {id} = useParams();

    const {info, loading} = useInfo()

    useEffect(() => {
        info && setBreadcrumbs([
            {
                path: routePaths.applications.index,
                title: t('sidebar.applications.title')
            },
            {
                path: null,
                title: t('labels.editing') + " - " + info.number
            },
            {
                path: null,
                title: t('labels.information')
            }
        ])
    }, [setBreadcrumbs, t, info])

    useEffect(() => {
        if(info){
            info.description && setDescription(info.description);
        }
    }, [info])

    if (loading) return <WhiteBlock>{t('labels.loading')}</WhiteBlock>

    const CustomSubmit = async () => {
        const newData = {
            description: description
        }

        const res = await ApplicationsServices.update(id, newData);

        if(res.statusCode === 200){
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }
    
    return (
        <WhiteBlock>
            <FormGroup label={t('labels.address')}>
                {info?.address ? info?.address : "-"}
            </FormGroup>
            <FormGroup label={t('labels.fullName')}>
                {info.user?.name ? info.user?.name : "-"}
            </FormGroup>
            <FormGroup label={t('labels.subscriber_type')}>
                {info.user?.type?.name ? info.user?.type?.name : "-"}
            </FormGroup>
            <FormGroup label={t('labels.phone_number')}>
                {info.user?.phone ? info.user?.phone : "-"}
            </FormGroup>
            <FormGroup label={t('labels.description')}>
                <TextArea type='text' value={description} onChange={e => setDescription(e.target.value)}></TextArea>
            </FormGroup>
            <div className="row row--multiline justify-end">
                <div className="col-auto">
                    <Button onClick={CustomSubmit}>{t('labels.save')}</Button>
                </div>
            </div>
        </WhiteBlock>
    );
};

export default ApplicationInformation;