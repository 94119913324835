import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../components/Input";
import Dictionaries from "../../../../services/operator/dictionaries";
import Button from "../../../../components/Button";
import CustomSelect from "../../../../components/CustomSelect";
import {toast} from "react-toastify";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import Checkbox from "../../../../components/Checkbox";
import {useAuth} from "../../../../navigation/Auth/ProvideAuth";

const BankbooksFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, watch, setValue, control, reset} = useForm();

    

    const {userData} = useAuth();

    const cityId = watch('city_id', null);
    const streetId = watch('street_id', null);
    const houseId = watch('house_id', null);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [streets, setStreets] = useState();
    const [streetsLoading, setStreetsLoading] = useState(true);

    const [houses, setHouses] = useState();
    const [housesLoading, setHousesLoading] = useState(true);

    const [apartments, setApartments] = useState();
    const [apartmentsLoading, setApartmentsLoading] = useState(true);

    const fetchCities = useCallback(async () => {
        setCitiesLoading(true);

        const res = await Dictionaries.cities({queryString: `company_id=${userData.company_id}`});

        if (res.statusCode === 200) {
            setCities(res.content);
        } else {
            toast.error(res.message)
        }

        setCitiesLoading(false);
    }, [userData]);

    const fetchStreets = useCallback(async () => {
        setHouses(null);
        setApartments(null);
        setValue('street_id', null);
        setValue('house_id', null);
        setValue('apartment_id', null);

        if (cityId) {
            setStreetsLoading(true);

            const res = await Dictionaries.streets({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setStreets(res.content);
            } else {
                toast.error(res.message)
            }

            setStreetsLoading(false);
        }
    }, [setValue, cityId]);

    const fetchHouses = useCallback(async () => {
        setApartments(null);
        setValue('house_id', null);
        setValue('apartment_id', null);

        if (streetId) {
            setHousesLoading(true);

            const res = await Dictionaries.houses({queryString: `street_id=${streetId}`});

            if (res.statusCode === 200) {
                setHouses(res.content);
            } else {
                toast.error(res.message)
            }

            setHousesLoading(false);
        }
    }, [setValue, streetId]);

    const fetchApartments = useCallback(async () => {
        setValue('apartment_id', null);

        if (houseId) {
            setApartmentsLoading(true);

            const res = await Dictionaries.apartments({queryString: `house_id=${houseId}`});

            if (res.statusCode === 200) {
                setApartments(res.content);
            } else {
                toast.error(res.message)
            }

            setApartmentsLoading(false);
        }
    }, [setValue, houseId]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchStreets();
    }, [fetchStreets]);

    useEffect(() => {
        fetchHouses();
    }, [fetchHouses]);

    useEffect(() => {
        fetchApartments();
    }, [fetchApartments]);

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({
            name: null,
            number: null,
            device_number: null,
            type: null,
            city_id: null,
            street_id: null,
            house_id: null,
            apartment_id: null,
            indication_status: null,
            device_expired: null,
        });
        setFilterData(Utils.objectToSearchParams({
            name: '',
            number: '',
            device_number: '',
            type: '',
            city_id: '',
            street_id: '',
            house_id: '',
            apartment_id: '',
            indication_status: '',
            device_expired: '',
        }))
    }

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.fullName')}>
                        <Input $small defaultValue={params.get('name')} type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.account_number')}>
                        <Input $small defaultValue={params.get('number')} type="text" {...register('number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.device_number')}>
                        <Input $small defaultValue={params.get('device_number')} type="text" {...register('device_number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.subscriber_type')}>
                        <Controller defaultValue={params.get('type')} name="type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.bankbookUserTypes}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={cities} onChange={onChange}
                                                    value={value}
                                                    isLoading={citiesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.street')}>
                        <Controller name="street_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={streets} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!cityId ? streetsLoading : false}
                                                    isDisabled={!!!cityId || streetsLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.house')}>
                        <Controller name="house_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={houses} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!streetId ? housesLoading : false}
                                                    isDisabled={!!!streetId || housesLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.apartment')}>
                        <Controller name="apartment_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={apartments} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!houseId ? (apartmentsLoading || housesLoading || streetsLoading || citiesLoading) : false} isDisabled={!!!houseId || (apartmentsLoading || housesLoading || streetsLoading || citiesLoading)}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.indication_status')}>
                        <Controller defaultValue={params.get('indication_status')} name="indication_status" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.indicationStatuses}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup style={{alignSelf: "center"}}>
                        <Checkbox {...register('device_expired')}>{t('labels.device_expired')}</Checkbox>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default BankbooksFilter;