import React from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import Utils from "../../../../services/utils";
import FormGroup from "../../../../components/FormGroup";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";

const UsersFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();

    const {handleSubmit, register, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null, phone: null, email: null});
        setFilterData(Utils.objectToSearchParams({name: '', phone: '', email: ''}))
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                <FormGroup label={t('labels.fullName')}>
                    <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                </FormGroup>
                <FormGroup label={t('labels.phone_number')}>
                    <Input defaultValue={params.get('phone')} $small type="tel" {...register('phone')}/>
                </FormGroup>
                <FormGroup label={t('labels.email')}>
                    <Input defaultValue={params.get('email')} $small type="text" {...register('email')}/>
                </FormGroup>
                <div className="mb-20">
                    <Button $small $fluid>{t('labels.apply')}</Button>
                </div>
                <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
            </form>
        </>
    );
};

export default UsersFilter;