import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class ApplicationsServices {
    static index = (queryString) => {
        queryString = queryString.replace('status', 'status_id');
        return getData(http, `/applications${queryString ? `?${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/applications/`, data)
    }

    static update = (id, data) => {
        return postData(http, `/applications/${id}`, data)
    }

    static view = (id) => {
        return getData(http, `/applications/${id}`)
    }

    static delete = (id) => {
        return deleteData(http, `/applications/${id}`)
    }

    static history = ({id}, queryString) => {
        return getData(http, `/applications/${id}/history${queryString ? `?${queryString}` : ""}`)
    }

    static confirmPayment = (id) => {
        return postData(http, `/applications/${id}/confirmPayment`)
    }

    static cancel = (id) => {
        return postData(http, `/applications/${id}/cancel`)
    }

    static services = {
        index: ({id}, queryString) => {
            return getData(http, `/applications/${id}/services${queryString ? `?${queryString}` : ""}`)
        },
        attach: ({id, data, queryString}) => {
            return postData(http, `/applications/${id}/services/attach${queryString ? `?${queryString}` : ""}`, data)
        },
        update: ({id, data, queryString}) => {
            return postData(http, `/applications/${id}/services/update${queryString ? `?${queryString}` : ""}`, data)
        },
        detach: ({parentId, id}) => {
            return postData(http, `/applications/${parentId}/services/detach`, {service_id: id})
        }
    }

    static specialists = {
        index: ({id}, queryString) => {
            return getData(http, `/applications/${id}/specialists${queryString ? `?${queryString}` : ""}`)
        },
        attach: (id, data) => {
            return postData(http, `/applications/${id}/specialists/attach`, data)
        },
        detach: (parentId, id) => {
            return postData(http, `/applications/${parentId}/specialists/detach`, {user_id: id})
        }
    }
}