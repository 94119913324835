import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import Dictionaries from "../../../../../services/operator/dictionaries";
import Utils from "../../../../../services/utils";
import FormGroup from "../../../../../components/FormGroup";
import Input from "../../../../../components/Input";
import Button from "../../../../../components/Button";
import CustomSelect from "../../../../../components/CustomSelect";
import {toast} from "react-toastify";

const MicrodistrictsFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, watch, control, setValue, reset} = useForm();

    

    const regionId = watch('region_id', null);
    const cityId = watch('city_id', null);
    const districtId = watch('district_id', null);

    const [regions, setRegions] = useState();
    const [regionsLoading, setRegionsLoading] = useState(true);

    const [cities, setCities] = useState();
    const [citiesLoading, setCitiesLoading] = useState(true);

    const [districts, setDistricts] = useState();
    const [districtsLoading, setDistrictsLoading] = useState(true);

    const fetchRegions = useCallback(async () => {
        setRegionsLoading(true);
        const res = await Dictionaries.regions();

        if (res.statusCode === 200) {
            setRegions(res.content);
        } else {
            toast.error(res.message)
        }
        setRegionsLoading(false);
    }, []);

    const fetchCities = useCallback(async () => {
        setCities(null)

        if (regionId) {
            setCitiesLoading(true);

            const res = await Dictionaries.cities({queryString: `region_id=${regionId}`});

            if (res.statusCode === 200) {
                setCities(res.content);

                if (!res.content.some(item => item.value === cityId)) {
                    setValue('city_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setCitiesLoading(false);
        } else {
            setValue('city_id', null)
        }
    }, [setValue, regionId]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetchDistricts = useCallback(async () => {
        if (cityId) {
            setDistrictsLoading(true);

            const res = await Dictionaries.districts({queryString: `city_id=${cityId}`});

            if (res.statusCode === 200) {
                setDistricts(res.content);

                if (!res.content.some(item => item.value === districtId)) {
                    setValue('district_id', null)
                }
            } else {
                toast.error(res.message)
            }

            setDistrictsLoading(false);
        } else {
            setValue('district_id', null)
        }
    }, [setValue, cityId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchRegions();
    }, [fetchRegions]);

    useEffect(() => {
        fetchCities();
    }, [fetchCities]);

    useEffect(() => {
        fetchDistricts();
    }, [fetchDistricts]);

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({name: null, region_id: null, city_id: null, district_id: null});
        setFilterData(Utils.objectToSearchParams({name: '', region_id: '', city_id: '', district_id: ''}))
    }

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.name')}>
                        <Input defaultValue={params.get('name')} $small type="text" {...register('name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.region')}>
                        <Controller name="region_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={regions} onChange={onChange}
                                                    value={value}
                                                    isLoading={regionsLoading}/>
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.city')}>
                        <Controller name="city_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={cities} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!regionId ? citiesLoading : false}
                                                    isDisabled={!!!regionId || citiesLoading}
                                                    $small
                                        />
                                    )}
                        />
                    </FormGroup>
                    <FormGroup label={t('labels.district')}>
                        <Controller name="district_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect $small options={districts} onChange={onChange}
                                                    value={value}
                                                    isLoading={!!cityId ? districtsLoading : false} isDisabled={!!!cityId || districtsLoading}/>
                                    )}/>
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default MicrodistrictsFilter;