import React from 'react';
import CustomLink from "./CustomLink";
import {Table, Tbody, Td, Th, Thead, Tr} from "./Table";
import {useTranslation} from "react-i18next";
import Button from "./Button";

const SubscribersList = ({subscribers, setSubscribers, chooseSubscriber, isLab}) => {
    const {t} = useTranslation()

    return (
        <>
            <div className="mb-20">
                <CustomLink onClick={() => setSubscribers([])}><i className="icon-chevron-left"/> Новый
                    поиск</CustomLink>
            </div>
            <Table>
                <Thead>
                    <Tr>
                        <Th width={"21%"}>{t('labels.account_number')}</Th>
                        {!isLab &&<Th width={"21%"}>{t('labels.phone_number')}</Th>}
                        <Th width={"21%"}>{t('labels.address')}</Th>
                        <Th width={"16%"}/>
                    </Tr>
                </Thead>
                <Tbody>
                    {subscribers.map(({id, name, number, phone, company, address, type}) => (
                        <Tr key={id}>
                            <Td>{number}</Td>
                            {!isLab && <Td>{phone}</Td>}
                            <Td>{address}</Td>
                            <Td>
                                <Button onClick={() => chooseSubscriber({id, company_id: company.id, name, address, type: type.id, phone: phone || ""})} $small>{t('labels.select')}</Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
};

export default SubscribersList;