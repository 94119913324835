import React, {useState} from 'react';
import FormGroup from "../../../../components/FormGroup";
import {useTranslation} from "react-i18next";
import Utils from "../../../../services/utils";
import {useForm, Controller} from "react-hook-form";
import Input from "../../../../components/Input";
import Dictionaries from "../../../../services/minAdmin/dictionaries";
import Button from "../../../../components/Button";
import CustomAsyncSelect from "../../../../components/CustomAsyncSelect";
import CustomSelect from '../../../../components/CustomSelect';

const IndicationsFilter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, control, reset} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    const handleReset = (e) => {
        e.preventDefault();
        reset({
            number: null,
            from: null,
            to: null
        });
        setFilterData(Utils.objectToSearchParams({
            number: '',
            from: '',
            to: ''
        }))
    }

    const systemTypes = [
        {label: t('labels.is_system'), value: '1'},
        {label: t('labels.is_user'), value: '0'}
    ]

    const open = () => {
        setIsOpened(!isOpened)
    }

    return (
        <div className="filter-container mb-20">
            <h3 onClick={open}>
                <span>Фильтр</span>
                <i className={isOpened ? "icon-chevron-up" : "icon-chevron-down"}></i>
            </h3>
            {isOpened &&
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%", marginTop:"20px"}}>
                <div className="filter-wrapper">
                    <FormGroup label={t('labels.resource_company')}>
                        <Controller defaultValue={params.get('company_id')} name="company_id" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.companies}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.account_number')}>
                        <Input $small defaultValue={params.get('number')} type="text" {...register('number')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.device_type')}>
                        <Controller defaultValue={params.get('type')} name="type" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomAsyncSelect
                                            method={Dictionaries.resourceTypes}
                                            onChange={onChange}
                                            value={value}
                                            $small
                                        />
                                    )}/>
                    </FormGroup>
                    <FormGroup label={t('labels.period')}>
                        <Input $small defaultValue={params.get('from')} type="date" {...register('from')}/>
                        <div className="mb-10"/>
                        <Input $small defaultValue={params.get('to')} type="date" {...register('to')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.sector_number')}>
                        <Input $small defaultValue={params.get('sector_name')} type="text" {...register('sector_name')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.last_changed_fullName')}>
                        <Input $small defaultValue={params.get('updated_by')} type="text" {...register('updated_by')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.who_approved')}>
                        <Controller name="is_system" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <CustomSelect options={systemTypes} onChange={onChange}
                                                      value={value}
                                                      $small
                                        />
                                    )}
                        />
                    </FormGroup>
                    </div>
                <div className="row row--multiline justify-end">
                    <div className="col-auto">
                        <Button  $small $fluid>{t('labels.apply')}</Button>
                    </div>
                    <div className="col-auto">
                        <Button onClick={handleReset} variant="outline" $small $fluid>{t('labels.reset')}</Button>
                    </div>
                </div>
            </form>
            }
        </div>
    );
};

export default IndicationsFilter;